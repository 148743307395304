<!--
   /**
      * about.vue
      * @module {src/modules/public}
      * @desc 前端关于我们
      * @author shiqiu
      * @date 2021-01-20
      */
-->
<template>
  <div>
    <el-row type="flex" justify="center" style="margin-top: 5vw;">
      <el-col :xs="24" :sm="24" :md="22" :lg="20" :xl="20">
        <div style="width: 100%; float: left;background-color: #fff;">
          <div style="padding: 20px 2.5%;">
            <el-row :gutter="10" class="margin_b_10 margin_t_10">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-bottom: 10px;cursor: pointer">
                <el-row :gutter="10" class="boxShadow_no_border">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-row :gutter="10">
                      <el-col :span="24">
                        <p style="font-size: 24px; color: #333333; padding-top: 10px; padding-bottom: 10px;text-align: center;border-bottom: 1px solid #dedede">{{this.$route.name}}</p>
                      </el-col>
                      <el-col :span="24" style="margin-top: 20px">
                        <p style="font-size: 20px; color: #333333; padding-top: 10px; padding-bottom: 10px">广州富融信息技术有限公司</p>
                        <p style="font-size: 14px; color: #666; padding-top: 10px; padding-bottom: 10px; line-height: 25px">成立于2014年7月，2014年至2019年主要致力于企业提供各种软件系统开发服务。2016年期间，开始对物业管理行业进行考察，并逐步为物业项目提供物联网建立的服务，深播数字化运营管理的种子。经过3年摸索与积累，2019年3月，富融物创上线，为中小物业社区提供以基础数据管理为核心，商业运营为驱动的一体化服务增值平台。</p>
                        <p style="font-size: 14px; color: #666; padding-top: 10px; padding-bottom: 10px; line-height: 25px">经验优势：团队拥有10年以上从事专业市场金融及物业服务的经验；团队拥有20多项知识产权和专利；团队拥有大型项目的组织和实施经验。</p>
                        <p style="font-size: 14px; color: #666; padding-top: 10px; padding-bottom: 10px; line-height: 25px">规模优势：现拥有4万多家中小企业客户，平台年交易额突破百亿元，已为623个物业项目提供数字化管理服务、为12000+家商户提供运营服务。</p>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>

</template>

<script>
export default {
  name: 'Login',
  components: {},
  data () {
    return {
    }
  },
  mounted () {

  },
  watch: {

  },
  methods: {

  },
  created () {
  }
}
</script>

<style lang="scss" scoped>
body {
  background: #fff !important;
}
</style>
